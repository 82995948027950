<template>
  <div>
    <!--    logo和导航栏-->
    <div :class="show ? 'navTitle' : 'navTitle1'">
      <navigation />
    </div>
    <div>
      <imageCom />
    </div>
    <!--      云端考试系统-->
    <div class="home">
      <exam />
    </div>
    <!--    页脚-->
    <div>
      <footers/>
    </div>
  </div>
</template>

<script>
import navigation from '@/components/home/navigation/index';
import imageCom from "./imageCom";
import exam from '@/components/home/exam/index'
import footers from '@/components/home/footers/index';
export default {
  name: "index",
  components: {
    navigation,
    imageCom,
    exam,
    footers
  },
  data() {
    return {
      show: false,
      picId:null,
      dataObj:{},
    }
  },
  watch:{

  },
  created() {

  },
  mounted() {
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 100) {
        this.show = true
      } else {
        this.show = false
      }
    })
  }
};
</script>

<style scoped lang="less">
.tab{
  width: 1200px;
  margin: 0 auto;
  //margin-left: 10%;

}
.content-father{
  //display: flex;
  //flex-direction: column;
  //flex-wrap: wrap;
  //justify-content: flex-start;
  //align-content: center;
  //align-items: flex-start;
}
.content{
  width: 1200px;
  margin: 0 auto;
  //display: flex;
  //flex-direction: row;
  //justify-content: center;
  //margin-bottom: 200px;
  /deep/.wrap{
    margin-bottom: 200px;
  }
}
</style>